import React from 'react'
import styles from './agentForm.module.scss'

const AgentForm = () => (
  <div>
    <div className={styles.detailsArea}>
      <form
        className="js-cm-form"
        id="subForm"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="2BE4EF332AA2E32596E38B640E90561960B417CF1923E8E3E9D6EF86B1437F97601BE21DF871AF7BF78D9504029A1A9BCB15B24C238DD879116813F2687ECC84"
      >
        <div>
          <input
            aria-label="First name"
            id="fieldjybydk"
            maxLength="200"
            name="cm-f-jybydk"
            required=""
            placeholder="First Name"
          />
        </div>
        <div>
          <input
            aria-label="Last name"
            id="fieldjybydd"
            maxLength="200"
            name="cm-f-jybydd"
            required=""
            placeholder="Last Name"
          />
        </div>
        <div>
          <input
            aria-label="Organisation"
            id="fieldjybydh"
            maxLength="200"
            name="cm-f-jybydh"
            placeholder="Organization"
          />
        </div>
        <div>
          <input
            autoComplete="Email"
            aria-label="Email"
            className="js-cm-email-input qa-input-email"
            id="fieldEmail"
            maxLength="200"
            name="cm-yhjljuh-yhjljuh"
            required=""
            type="email"
            placeholder="Email"
          />
        </div>
        <div>
          <input
            aria-label="Phone"
            id="fieldjybydu"
            maxLength="200"
            name="cm-f-jybydu"
            type="number"
            placeholder="Phone"
          />
        </div>
        <div>
          <input id="cm-privacy-consent" name="cm-privacy-consent" required="" type="checkbox" />
          <label
            htmlFor="cm-privacy-consent"
            className={styles.columnequals}
            style={{
              display: 'inline-block',
              marginLeft: '24px',
              verticalAlign: 'middle'
            }}
          >
            I agree to be emailed
          </label>
          <input id="cm-privacy-consent-hidden" name="cm-privacy-consent-hidden" type="hidden" value="true"></input>
        </div>
        <div>
          <input id="joinBtn" className="js-cm-submit-button" type="submit" />
        </div>
      </form>
    </div>
  </div>
)

export default AgentForm
