import React from 'react'
import LayoutTheme from '../components/layout/layout'
import { FaClock, FaHandPointer, FaMobile } from 'react-icons/fa'
import Hero from '../components/hero/hero'
import About from '../components/about/about.js'
import HowItWork from '../components/howItWorks/howItWorks'
import Benefits from '../components/benefits/benefits'
import Chat from '../components/chat/chat'
import AgentForm from '../components/agentForm/agentForm'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Agents = ({ data }) => {
  const [benefits] = [data.datoCmsAgentPage.benefits]
  benefits[0].icon = <FaMobile size={48} color={'#fba394'} />
  benefits[1].icon = <FaClock size={48} color={'#fba394'} />
  benefits[2].icon = <FaHandPointer size={48} color={'#fba394'} />
  return (
    <div>
      <HelmetDatoCms seo={data.datoCmsAgentPage.seoMetaTags} />
      <LayoutTheme headerTransparency={true}>
        <Hero wording={data.datoCmsAgentPage.hero} />
        <div className="bg-primary row">
          <div className="col-12">
            <About title={data.datoCmsAgentPage.aboutTitle} wording={data.datoCmsAgentPage.aboutDescription} />
          </div>

          <div className="col-12">
            <HowItWork steps={data.datoCmsAgentPage.howItWorkList} title={data.datoCmsAgentPage.howItWork} />
          </div>
          <div className="col-12">
            <Benefits benefits={benefits}>
              <div id="chatForm"></div>
            </Benefits>
          </div>
          <div className="col-12">
            <Chat title={data.datoCmsAgentPage.chatTitle} description={data.datoCmsAgentPage.chatDescription}>
              <AgentForm />
            </Chat>
          </div>
        </div>
      </LayoutTheme>
    </div>
  )
}

export default Agents

export const query = graphql`
  query AgentQuery {
    datoCmsAgentPage {
      hero
      aboutTitle
      aboutDescription
      howItWork
      howItWorkList {
        id
        title
        description
      }
      benefit
      benefits {
        id
        title
        description
      }
      chatTitle
      chatDescription
      seoMetaTags {
        tags
      }
    }
  }
`
